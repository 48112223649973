// import React from "react";
// import { RouterProvider } from "react-router-dom";
// import { router } from "./navigation/Router";
// import { ThemeProvider } from "styled-components";
// import { global } from "./style/global";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// const queryClient = new QueryClient();

// function App() {
//   return (
//     <ThemeProvider theme={global}>
//       <QueryClientProvider client={queryClient}>
//         <RouterProvider router={router} />
//       </QueryClientProvider>
//     </ThemeProvider>
//   );
// }

// export default App;

import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/Router";
import { ThemeProvider } from "styled-components";
import { global } from "./style/global";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setUser } from "./redux/reduxSlice/authSlice";

const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    if (token && user) {
      dispatch(setUser(user)); // Restore Redux state from localStorage
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={global}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
