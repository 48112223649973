import { createSlice } from "@reduxjs/toolkit";

const commentsSlice = createSlice({
  name: "comments",
  initialState: [],
  reducers: {
    addComment: (state, action) => {
      // console.log(action.payload,
      //   'action.payload'
      // )
      state.push(action.payload);
    },
    
  },
});

export const { addComment, deleteComment, updateComment } = commentsSlice.actions;

export default commentsSlice.reducer;
